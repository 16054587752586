import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MatterScene from "../Primary/MatterScene";
import NoSleep from "nosleep.js";

export const logoBreakpoint = 817;

const drawerWidth = 240;

export default function PersistentDrawerRight() {
  const [height, setHeight] = useState(document.documentElement.clientHeight);
  const [spectrumColorToggle, setSpectrumColorToggle] = useState(true);
  const [spectrumClass, setSpectrumClass] = useState("");
  const [spectrumVariant, setSpectrumVariant] = useState("");
  const [firstUpdate, setFirstUpdate] = useState(true);
  const handleSetSpectrumColor = () => {
    const setGreen = () => {
      setSpectrumColorToggle(true);
      window.spectrumColorCalculator = (num) => `rgb(0, ${num}, 200)`;
      setSpectrumVariant("green");
      setSpectrumClass("#00fac8");
      localStorage.setItem("spectrumColor", "green");
    };
    const setPurple = () => {
      setSpectrumColorToggle(false);
      window.spectrumColorCalculator = (num) => `rgb(${num}, 0, 200)`;
      setSpectrumVariant("purple");
      setSpectrumClass("#dd00ff");
      localStorage.setItem("spectrumColor", "purple");
    };
    if (firstUpdate === true) {
      if (localStorage.getItem("spectrumColor") === "purple") {
        setPurple();
      } else if (localStorage.getItem("spectrumColor") === "green") {
        setGreen();
      } else {
        setGreen();
      }
      setFirstUpdate(false);
    } else if (spectrumColorToggle) {
      setPurple();
    } else {
      setGreen();
    }
  };
  useEffect(() => {
    window.addEventListener("resize", (e) =>
      setHeight(document.documentElement.clientHeight)
    );
    window.addEventListener("orientationchange", (e) =>
      setHeight(document.documentElement.clientHeight)
    );
    handleSetSpectrumColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      fontFamily: "Major Mono Display",
      overflow: "hidden",
      display: "flex",
      height: height,
      userSelect: "none",
      fontDisplay: "optional",
      background: "#13131a",
      width: "100vw",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      background: "#00050c",
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
      maxHeight: "64px",
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
    },
    title: {
      flexGrow: 1,
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
      color: "#7f7aff",
    },
    toolbar: {
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
    },
    hide: {
      display: "none",
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
      fontDisplay: "optional",
      fontFamily: "Major Mono Display",
    },
    currentFilterSvg: {
      position: "absolute",
      right: 40,
      top: 1,
      cursor: "context-menu",
    },
  }));
  const classes = useStyles();

  const [renderLogo, setRenderLogo] = useState(
    document.documentElement.clientWidth > logoBreakpoint
  );

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      if (document.documentElement.clientWidth <= logoBreakpoint) {
        setRenderLogo(false);
      } else if (
        !renderLogo &&
        document.documentElement.clientWidth > logoBreakpoint
      ) {
        setRenderLogo(true);
      }
    });
    window.addEventListener("orientationchange", (e) => {
      if (document.documentElement.clientWidth <= logoBreakpoint) {
        setRenderLogo(false);
      } else if (
        !renderLogo &&
        document.documentElement.clientWidth > logoBreakpoint
      ) {
        setRenderLogo(true);
      }
    });
  }, [renderLogo]);

  // eslint-disable-next-line no-unused-vars
  const noSleep = useRef(new NoSleep());
  useEffect(() => {
    // Enable wake lock.
    // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
    document.addEventListener(
      "click",
      function enableNoSleep() {
        document.removeEventListener("click", enableNoSleep, false);
        noSleep.current.enable();
      },
      false
    );
  }, []);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: true,
        })}
        onClick={(e) => {
          if (!renderLogo) {
            const target = e.currentTarget;
            const rect = target.getBoundingClientRect();
            const bottomBorderWidth = 10;

            // Calculate the y position of the click inside the element
            const clickYPositionInsideElement = e.clientY - rect.top;

            // Check if the click was within the bottom border area
            if (clickYPositionInsideElement > rect.height - bottomBorderWidth) {
              handleSetSpectrumColor();
            }
          }
        }}
      >
        <Toolbar className={classes.toolBar}>
          {renderLogo && (
            <>
              <span className="logo" />
              <span
                onClick={() => handleSetSpectrumColor()}
                className={`u spectrum--${spectrumVariant}`}
              />
              <Typography variant="h4" noWrap className={classes.title}>
                pl&nbsp;y With so&nbsp;nd
              </Typography>
              <span className={`pyramid pyramid--${spectrumVariant}`} />
            </>
          )}
        </Toolbar>
        <span className={classes.currentFilterSvg} id="filter-svg" />
      </AppBar>
      <MatterScene spectrumClass={spectrumClass} />
    </div>
  );
}
