import React, { useEffect } from "react";
import AppBar from "./Utility/AppBar";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

((global, exports, perf) => {
  const fixSetTarget = (param) => {
    if (!param)
      // if NYI, just return
      return;
    if (!param.setTargetAtTime)
      param.setTargetAtTime = param.setTargetValueAtTime;
  };

  if (
    window.hasOwnProperty("webkitAudioContext") &&
    !window.hasOwnProperty("AudioContext")
  ) {
    window.AudioContext = window.webkitAudioContext;

    if (!AudioContext.prototype.hasOwnProperty("createGain"))
      AudioContext.prototype.createGain = AudioContext.prototype.createGainNode;
    if (!AudioContext.prototype.hasOwnProperty("createDelay"))
      AudioContext.prototype.createDelay =
        AudioContext.prototype.createDelayNode;
    if (!AudioContext.prototype.hasOwnProperty("createScriptProcessor"))
      AudioContext.prototype.createScriptProcessor =
        AudioContext.prototype.createJavaScriptNode;
    if (!AudioContext.prototype.hasOwnProperty("createPeriodicWave"))
      AudioContext.prototype.createPeriodicWave =
        AudioContext.prototype.createWaveTable;

    AudioContext.prototype.internal_createGain =
      AudioContext.prototype.createGain;
    AudioContext.prototype.createGain = () => {
      var node = this.internal_createGain();
      fixSetTarget(node.gain);
      return node;
    };

    AudioContext.prototype.internal_createDelay =
      AudioContext.prototype.createDelay;
    AudioContext.prototype.createDelay = (maxDelayTime) => {
      var node = maxDelayTime
        ? this.internal_createDelay(maxDelayTime)
        : this.internal_createDelay();
      fixSetTarget(node.delayTime);
      return node;
    };

    AudioContext.prototype.internal_createBufferSource =
      AudioContext.prototype.createBufferSource;
    AudioContext.prototype.createBufferSource = () => {
      var node = this.internal_createBufferSource();
      if (!node.start) {
        node.start = (when, offset, duration) => {
          if (offset || duration) this.noteGrainOn(when || 0, offset, duration);
          else this.noteOn(when || 0);
        };
      } else {
        node.internal_start = node.start;
        node.start = (when, offset, duration) => {
          if (typeof duration !== "undefined")
            node.internal_start(when || 0, offset, duration);
          else node.internal_start(when || 0, offset || 0);
        };
      }
      if (!node.stop) {
        node.stop = (when) => {
          this.noteOff(when || 0);
        };
      } else {
        node.internal_stop = node.stop;
        node.stop = (when) => {
          node.internal_stop(when || 0);
        };
      }
      fixSetTarget(node.playbackRate);
      return node;
    };

    AudioContext.prototype.internal_createDynamicsCompressor =
      AudioContext.prototype.createDynamicsCompressor;
    AudioContext.prototype.createDynamicsCompressor = () => {
      var node = this.internal_createDynamicsCompressor();
      fixSetTarget(node.threshold);
      fixSetTarget(node.knee);
      fixSetTarget(node.ratio);
      fixSetTarget(node.reduction);
      fixSetTarget(node.attack);
      fixSetTarget(node.release);
      return node;
    };

    AudioContext.prototype.internal_createBiquadFilter =
      AudioContext.prototype.createBiquadFilter;
    AudioContext.prototype.createBiquadFilter = () => {
      var node = this.internal_createBiquadFilter();
      fixSetTarget(node.frequency);
      fixSetTarget(node.detune);
      fixSetTarget(node.Q);
      fixSetTarget(node.gain);
      return node;
    };

    if (AudioContext.prototype.hasOwnProperty("createOscillator")) {
      AudioContext.prototype.internal_createOscillator =
        AudioContext.prototype.createOscillator;
      AudioContext.prototype.createOscillator = () => {
        var node = this.internal_createOscillator();
        if (!node.start) {
          node.start = (when) => {
            this.noteOn(when || 0);
          };
        } else {
          node.internal_start = node.start;
          node.start = (when) => {
            node.internal_start(when || 0);
          };
        }
        if (!node.stop) {
          node.stop = (when) => {
            this.noteOff(when || 0);
          };
        } else {
          node.internal_stop = node.stop;
          node.stop = (when) => {
            node.internal_stop(when || 0);
          };
        }
        if (!node.setPeriodicWave) node.setPeriodicWave = node.setWaveTable;
        fixSetTarget(node.frequency);
        fixSetTarget(node.detune);
        return node;
      };
    }
  }

  if (
    window.hasOwnProperty("webkitOfflineAudioContext") &&
    !window.hasOwnProperty("OfflineAudioContext")
  ) {
    window.OfflineAudioContext = window.webkitOfflineAudioContext;
  }
})(window);

const theme = createTheme({
  palette: {
    primary: {
      main: "#0b0b14",
    },
    secondary: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: '"Major Mono Display", monospace',
  },
});

const App = () => {
  useEffect(() => {
    let wakeLock = null;

    // Function to request the wake lock
    const requestWakeLock = async () => {
      try {
        if ("wakeLock" in navigator && document.visibilityState === "visible") {
          wakeLock = await navigator.wakeLock.request("screen");
          console.log("Screen Wake Lock is active");

          // Listen for the release event and log
          wakeLock.addEventListener("release", () => {
            console.log("Screen Wake Lock was released");
            // Re-request the wake lock if necessary, considering user interaction requirements
          });
        }
      } catch (e) {
        console.error(`Screen Wake Lock error: ${e.name}, ${e.message}`);
      }
    };

    // Request wake lock initially
    requestWakeLock();

    // Set up an event listener for visibility changes to re-request the wake lock
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        requestWakeLock();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up: remove the event listener when the component unmounts or dependencies change
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (wakeLock !== null) {
        wakeLock.release().then(() => {
          console.log("Screen Wake Lock was released upon cleanup");
        });
      }
    };
  }, []);

  // const [dontShowAgain, setDontShowAgain] = useState(false);
  // const [consent, setConsent] = useState(
  //   Boolean(localStorage.getItem("consent"))
  // );

  return (
    <ThemeProvider theme={theme}>
      {/* {!consent && (
        <div className="consent-container">
          <div>
            <div className="consent-text">
              <p>
                Please be informed: This website produces visual and audio
                experiences.
              </p>
              <p>To navigate to the application, click 'Start'.</p>
              <form id="consent-form">
                <label htmlFor="dont-show-again">
                  <input
                    type="checkbox"
                    id="dont-show-again"
                    name="dont-show-again"
                    checked={dontShowAgain}
                    onChange={() => setDontShowAgain(!dontShowAgain)}
                  />
                  Skip this message on page refresh
                </label>
                <button
                  className="consent-submit"
                  type="submit"
                  onClick={() => {
                    if (dontShowAgain) {
                      localStorage.setItem("consent", "true");
                    }
                    setConsent(true);
                  }}
                >
                  Start
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      {consent && <AppBar />} */}
      <AppBar />
    </ThemeProvider>
  );
};

export default App;
