import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText } from "@material-ui/core";

export default function TrackList(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "112%",
      maxWidth: 360,
      background: "primary",
    },
    track: {
      height: "3em",
      color: "#2f3132",
      "&:hover": {
        color: "#afeaf3",
      },
      "@media (min-width: 1280px)": {
        "&:focus": {
          background: "#000012",
          color: "#afeaf3",
        },
      },
    },
    text: {
      left: "0rem",
    },
    playing: {
      color: "#00cc00",
    },
  }));
  const classes = useStyles();
  const tracks = [
    {
      source: "trance",
      displayName: "trance",
    },
    {
      source: "reggae",
      displayName: "reggae",
    },
    {
      source: "acoustic",
      displayName: "acoustic",
    },
    {
      source: "keys",
      displayName: "keys",
    },
    {
      source: "house",
      displayName: "house",
    },
    {
      source: "deep-house",
      displayName: "deep house",
    },
    {
      source: "trance-2",
      displayName: "Trance 2",
    },
    {
      source: "electro-house",
      displayName: "EleCTRO house",
    },
    {
      source: "psy-trance",
      displayName: "psy trAnce",
    },
    {
      source: "complextro",
      displayName: "COMPLEXTRO",
    },
    {
      source: "uplifting",
      displayName: "uplifting",
    },
    {
      source: "electro-trap",
      displayName: "electro trap",
    },
    {
      source: "trance-3",
      displayName: "trance 3",
    },
    {
      source: "trance-4",
      displayName: "TRANCE 4",
    },
  ];
  return (
    <List component="nav" className={classes.root} aria-label="tracks">
      {tracks.map((n) => {
        const { source, displayName } = n;
        const trackCDN = process.env.REACT_APP_AUDIO_CDN_URL + source + ".m4a";
        return (
          <ListItem
            button
            className={
              props.playingTrack === trackCDN
                ? `${classes.track} ${classes.playing}`
                : classes.track
            }
            onClick={() => {
              if (props.playing) {
                props.togglePlayButton().then(() => {
                  props.handleTrackInput(trackCDN);
                });
              } else {
                props.handleTrackInput(trackCDN);
              }
            }}
            key={displayName}
            aria-label={displayName.toLowerCase()}
          >
            <ListItemText className="text" inset primary={displayName} />
          </ListItem>
        );
      })}
    </List>
  );
}
