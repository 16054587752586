import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function Visualizer(props) {
  const [height, setHeight] = useState(window.innerHeight);
  const useStyles = makeStyles((theme) => ({
    spectrum: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      top: 0,
      background: "#13131A",
      transform: "rotatex(180deg)",
      height: height,
      overflowY: "hidden",
    },
  }));

  const classes = useStyles();

  window.addEventListener("resize", (e) => setHeight(window.innerHeight));
  window.addEventListener("orientationchange", (e) =>
    setHeight(window.innerHeight)
  );

  return (
    <div className={classes.spectrum}>
      {props.frequencyBandArray.map((num) => (
        <span id={num} key={num} />
      ))}
    </div>
  );
}
