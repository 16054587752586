import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  const popperRef = React.useRef(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const BandwidthSlider = withStyles({
  root: {
    color: "#606060",
    height: 8,
    width: "70%",
    left: "3em",
    marginBottom: "14px",
    bottom: ".3em",
  },
  thumb: {
    height: 24,
    width: 24,
    background: "#2a2a2a",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
    color: "transparent",
    fontWeight: "bold",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default function CustomizedSlider(props) {
  const useStyles = makeStyles((theme) => ({
    root: { cursor: "ew-resize" },
  }));
  const { filterType } = props;
  const sliderValues = {
    max: filterType === "peaking" ? 12 : 40,
    step: filterType === "peaking" ? 0.0001 : 0.001,
  };
  const { max, step } = sliderValues;
  const classes = useStyles();
  return (
    <div style={{ display: "flex" }}>
      <BandwidthSlider
        valueLabelDisplay="auto"
        aria-label="Bandwidth"
        aria-labelledby="bandwidth"
        defaultValue={props.qSliderValue}
        // value={props.qSliderValue}
        min={0.001}
        max={max}
        step={step}
        text={props.text}
        onChange={(event, newValue) => props.handleQChange(newValue)}
        className={classes.root}
      />
    </div>
  );
}
