import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./Components/App";
import * as serviceWorker from "./serviceWorker";

/* 

LICENSES

Matter.js https://github.com/liabru/matter-js/blob/master/LICENSE

Rose SVG https://www.svgrepo.com/page/licensing#MIT

Filter SVGs https://www.svgrepo.com/page/licensing#MIT

Music and audio files are not licensed.

*/

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
