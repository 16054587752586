import React, { useEffect, useRef, useState } from "react";
import EqControls from "../Utility/EqControls";
import Matter from "matter-js";
import Visualizer from "../Utility/Visualizer";
import Band from "../../sprites/band.svg";

// const Matter = window.Matter;

const spectrumHeights = {
  high: 272.5,
  mid: 500,
  low: 2200,
};

const spectrumHeight =
  parseFloat(localStorage.getItem("spectrumHeight")) || spectrumHeights.low;

const pushPlay = (e) => {
  const playButton = document.getElementById("play-button");
  if (playButton !== document.activeElement) {
    e.preventDefault();
    playButton.focus();
    playButton.click();
  }
};

function MakeSound(props) {
  const filter = useRef(null);
  const analyserNode = useRef({});
  const trackSource = useRef({});

  //TODO: Improve the descriptiveness of these variable names.
  let path = useRef([]);
  let pathCopy = useRef([]);
  let context = useRef({});
  let gain = useRef({});
  let num = useRef(0);
  const amplitudeValues = useRef(null);
  let uploadedFile = useRef(false);

  let loopCountdownRef = useRef(props.loopCountdownInit);
  useEffect(() => {
    loopCountdownRef.current = props.loopCountdown;
  }, [props.loopCountdown]);

  const [playing, setPlaying] = useState(false);

  const [playingTrack, setPlayingTrack] = useState(null);

  function getCurrentFreqBandLocation() {
    let freqBand = props.render.engine.world.bodies.find(
      (b) => b.render.fillStyle === "#ff6600"
    );
    let filterNum;
    const { type } = filter.current;
    if (["notch", "bandpass"].includes(type)) {
      filterNum = freqBand.position["x"] * 13;
    } else if (type === "peaking") {
      filterNum =
        (freqBand.position["x"] / props.render.canvas.width) * 1000 * 30;
    } else {
      filterNum = (freqBand.position["x"] / props.render.canvas.width) * 1000;
    }
    filter.current.frequency.value = filterNum;
    let filterGain = freqBand.position["y"] - 200;
    filter.current.gain.value = -filterGain;
    requestAnimationFrame(getCurrentFreqBandLocation);
  }

  const setPosition = () => {
    let freqBand = props.render.engine.world.bodies.find(
      (b) => b.render.fillStyle === "#ff6600"
    );
    if (window.looping === false) {
      path.current = [];
      pathCopy.current = [];
      num.current = 0;
      Matter.World.remove(props.render.engine.world, freqBand);
      Matter.World.addBody(
        props.render.engine.world,
        Matter.Bodies.circle(40, 105, props.bandRadius, {
          restitution: props.bandRestitution,
          render: {
            fillStyle: "#ff6600",
            sprite: {
              texture: Band,
            },
          },
        })
      );
      return;
    } else {
      freqBand.position = {
        x: path.current[num.current][0],
        y: path.current[num.current][1],
      };
      num.current++;
      if (num.current === path.current.length) {
        num.current = 0;
      }
      requestAnimationFrame(setPosition);
    }
  };

  const recordPath = () => {
    let freqBand = props.render.engine.world.bodies.find(
      (b) => b.render.fillStyle === "#ff6600"
    );
    if (window.looping === false) {
      path.current = [];
      pathCopy.current = [];
      num.current = 0;
      Matter.World.remove(props.render.engine.world, freqBand);
      Matter.World.addBody(
        props.render.engine.world,
        Matter.Bodies.circle(0, 42, props.bandRadius, {
          restitution: props.bandRestitution,
          render: {
            fillStyle: "#ff6600",
            sprite: {
              texture: Band,
            },
          },
        })
      );
      return;
    } else {
      if (loopCountdownRef.current >= 0) {
        let currentPath = path.current;
        currentPath.push([freqBand.position["x"], freqBand.position["y"]]);
        path.current = currentPath;
        requestAnimationFrame(recordPath);
      } else {
        let currentPathCopy = path.current;
        pathCopy.current = [...currentPathCopy];
        setPosition();
      }
    }
  };

  const [looping, setLooping] = useState(false);
  function toggleLoop() {
    props.initializeLoopCountdown();
    if (looping) {
      setLooping(false);
      window.looping = false;
      return;
    }
    setLooping(true);
    window.looping = true;
    recordPath();
    let countdown = props.loopCountdownInit;
    const updateCountdown = () => {
      countdown--;
      props.setLoopcountdown(countdown);
      if (countdown >= 0) {
        setTimeout(updateCountdown, 1000);
      } else {
        props.setLoopcountdown(-1);
      }
    };
    // Start the countdown
    setTimeout(updateCountdown, 1000);
  }

  window.addEventListener("resize", () => {
    if (window.looping && loopCountdownRef.current < 0) {
      window.looping = false;
      props.initializeLoopCountdown();
      setLooping(false);
    }
  });

  window.addEventListener("orientationchange", () => {
    if (window.looping && loopCountdownRef.current < 0) {
      window.looping = false;
      props.initializeLoopCountdown();
      setLooping(false);
    }
  });

  function togglePlayButton() {
    setPlayPausePromiseResolved(false);
    return new Promise((resolve, reject) => {
      if (
        (uploadedFile.current === true &&
          playing &&
          context.current.state === "running") ||
        (playing && context.current.state === "running")
      ) {
        setPlaying(false);
        context.current
          .suspend()
          .then(() => {
            resolve(); // Resolve the promise after suspension
          })
          .catch(reject); // Reject the promise if suspend fails
      } else if (
        (uploadedFile.current === true &&
          playing === false &&
          context.current.state === "suspended") ||
        (playing === false && context.current.state === "suspended")
      ) {
        setPlaying(true);
        context.current
          .resume()
          .then(() => {
            resolve(); // Resolve the promise after resuming
          })
          .catch(reject); // Reject the promise if resume fails
      } else {
        resolve(); // Resolve immediately if neither condition is met
      }
    });
  }

  const [playPausePromiseResolved, setPlayPausePromiseResolved] =
    useState(false);

  const handleTrackInput = (track) => {
    if (context.current && context.current.state === "running") {
      context.current.close();
      context.current = null;
    }

    if (trackSource.current instanceof Audio) {
      trackSource.current.pause();
      trackSource.current.removeAttribute("src");
      trackSource.current.load();
      trackSource.current = null;
    } //TODO: Remove because it is unused

    const AudioContext = window.AudioContext || window.webkitAudioContext;
    let audioCtx;

    // Function to setup audio processing
    const setupAudioProcessing = (audioCtx, buffer = null) => {
      if (trackSource.current instanceof Audio) {
        trackSource.current.pause();
        trackSource.current.removeAttribute("src");
        trackSource.current.load();
        trackSource.current = null;
      } //TODO: Remove because it is unused

      const audioFile = new Audio();
      audioFile.crossOrigin = "anonymous";
      audioFile.src = track;

      // Add event listener for audio end
      audioFile.addEventListener("ended", (e) => {
        pushPlay(e);
      });

      let source = audioCtx.createMediaElementSource(audioFile);
      const biquadFilter = audioCtx.createBiquadFilter();
      biquadFilter.type = filterType;
      biquadFilter.frequency.value = 0;
      biquadFilter.Q.value = qSliderValue;
      source.connect(biquadFilter);
      biquadFilter.connect(audioCtx.destination);

      if (buffer) {
        const bufferSource = audioCtx.createBufferSource();
        bufferSource.buffer = buffer;
        bufferSource.connect(biquadFilter);
        // Listen for when the buffer source node finishes playback
        bufferSource.onended = (e) => {
          pushPlay(e);
        };
        bufferSource.start(0);
      } else {
        audioFile.play();
      }

      filter.current = biquadFilter;
      const analyser = audioCtx.createAnalyser();
      analyser.fftSize = 2048;
      filter.current.connect(analyser);
      analyserNode.current = analyser;

      const gainNode = audioCtx.createGain();
      gainNode.gain.setValueAtTime(0, audioCtx.currentTime);
      filter.current.connect(gainNode);
      gainNode.connect(audioCtx.destination);
      gain.current = gainNode;
      context.current = audioCtx;

      setPlayingTrack(buffer ? null : track);
      runSpectrum();
      setPlaying(true);
      props.applySpectrumBackground();
      requestAnimationFrame(getCurrentFreqBandLocation);
    };

    // Decode audio data for user-uploaded files
    if (typeof track !== "string") {
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(track);
      fileReader.onload = (e) => {
        audioCtx = new AudioContext();
        audioCtx.decodeAudioData(e.target.result, (buffer) => {
          // Create AudioContext with the sample rate of the uploaded file
          audioCtx.close();
          audioCtx = new AudioContext({ sampleRate: buffer.sampleRate });
          setupAudioProcessing(audioCtx, buffer);
        });
      };
    } else {
      // Use default AudioContext for in-app audio
      audioCtx = new AudioContext();
      setupAudioProcessing(audioCtx);
    }
    setPlayPausePromiseResolved(true);
  };

  const runSpectrum = () => {
    const bufferLength = analyserNode.current.frequencyBinCount;
    const amplitudeArray = new Uint8Array(bufferLength);
    analyserNode.current.getByteFrequencyData(amplitudeArray);
    adjustFreqBandStyle(amplitudeArray);
    requestAnimationFrame(runSpectrum);
  };

  if (!window.amplitudeHeightDiff) {
    window.amplitudeHeightDiff = spectrumHeight;
  }

  const adjustFreqBandStyle = (newAmplitudeData) => {
    amplitudeValues.current = newAmplitudeData;
    let domElements = props.frequencyBandArray.map((num) =>
      document.getElementById(num)
    );
    props.frequencyBandArray.forEach((num) => {
      domElements[num].style.backgroundColor = window.spectrumColorCalculator(
        amplitudeValues.current[num] - 25
      );
      domElements[num].style.height = `${
        amplitudeValues.current[num] *
        (window.innerHeight / window.amplitudeHeightDiff) *
        0.0625
      }rem`;
      domElements[num].style.width = `${
        ((window.outerWidth - 275) / 2000) * 0.1345625
      }rem`;
    });
  };

  const handleFilterChange = (event) => {
    if (filter.current !== null) {
      filter.current.type = event.target.value;
      localStorage.setItem("filter", event.target.value);
      setFilterType(event.target.value);
    } else {
      localStorage.setItem("filter", event.target.value);
      setFilterType(event.target.value);
    }
  };

  const [filterType, setFilterType] = React.useState(
    localStorage.getItem("filter") ?? "allpass"
  );

  const [qSliderValue, setQSliderValue] = React.useState(5);

  function handleQChange(val) {
    if (filter.current !== null) {
      setQSliderValue(val);
      filter.current.Q.value = val;
    } else {
      setQSliderValue(val);
    }
  }

  const [gainsliderValue, setGain] = React.useState(0);

  function handleGainSliderChange(val) {
    if (filter.current !== null) {
      setGain(val);
      changeGain(filter, val);
    } else {
      setGain(val);
    }
  }

  const [masterGain, setMasterGain] = React.useState(0.01);

  function adjustGain(node, val) {
    if (filter.current !== null) {
      node.gain.exponentialRampToValueAtTime(
        val,
        node.context.currentTime + 0.05
      );
      setMasterGain(val);
    } else {
      setMasterGain(val);
    }
  }

  function changeGain(filter, val) {
    filter.current.gain.value = val * 360;
  }

  return (
    <>
      <EqControls
        handleFilterChange={handleFilterChange}
        filterType={filterType}
        looping={looping}
        toggleLoop={toggleLoop}
        qSliderValue={qSliderValue}
        handleQChange={handleQChange}
        changeGain={changeGain}
        gain={gain}
        adjustGain={adjustGain}
        playing={playing}
        gainsliderValue={gainsliderValue}
        handleGainSliderChange={handleGainSliderChange}
        masterGain={masterGain}
        togglePlayButton={togglePlayButton}
        handleTrackInput={handleTrackInput}
        playingTrack={playingTrack}
        spectrumHeights={spectrumHeights}
        spectrumHeight={spectrumHeight}
        loopCountdown={props.loopCountdown}
        playPausePromiseResolved={playPausePromiseResolved}
        setPlayPausePromiseResolved={setPlayPausePromiseResolved}
        context={context}
        spectrumClass={props.spectrumClass}
      />
      <Visualizer frequencyBandArray={props.frequencyBandArray} />
    </>
  );
}

export default MakeSound;
